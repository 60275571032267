
import Routing from "./components/navRouting/routingHome.jsx"

function App() {
  return (
    <Routing/>
  );
}

export default App;
