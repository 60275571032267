class Project {
    constructor(title, component, tags, image) {
        this.title = title;
        this.component = component;
        this.tags = tags;
        this.image = image;
        
      }
}

export default Project